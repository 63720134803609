import { createContext, useEffect, useState } from 'react'
import EntiendoSDK, { ThisUser } from 'entiendo-javascript-sdk'

interface AuthProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface AuthContextValue {
    authenticated: boolean | null
    user: ThisUser | null
    authenticate(options: AuthenticateOptions): Promise<void>
    updateUser(changes: Partial<ThisUser>): void
    signOut(): Promise<void>
}

export type AuthenticateOptions = {
    accessToken: string
    refreshToken: string
    expires: Date
}

const AuthContext = createContext<AuthContextValue>({
    authenticated: null,
    user: null,
    authenticate: async () => {},
    updateUser: () => {},
    signOut: async () => {}
})

const AuthProvider = (props: AuthProviderProps) => {

    const [ authenticated, setAuthenticated ] = useState<boolean | null>(null)
    const [ user, setUser ] = useState<ThisUser | null>(null)

    const getUser = async (): Promise<ThisUser> => {
        const response = await EntiendoSDK.user.me() 
        return response?.data ?? null
    }

    const updateUser = (changes: Partial<ThisUser>) => {
        if (user) {
            setUser({ ...user, ...changes })
        }
    }

    const getAuthenticationCache = () => {
        const json = localStorage.getItem('auth')
        return json ? JSON.parse(json) : null
    }

    const setAuthenticationCache = (auth: any) => {
        localStorage.setItem('auth', JSON.stringify(auth))
    }

    const updateTheme = () => {
        const html = window.document.getElementsByTagName('html')[0]
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            html.dataset.theme = 'dark'
        } else {
            html.dataset.theme = 'light'
        }
    }

    const authenticate = async ({ accessToken, refreshToken, expires }: AuthenticateOptions) => {
        const user = await getUser()
        if (!user) throw new Error('Failed to get user data')
        setAuthenticationCache({
            accessToken,
            refreshToken,
            expires,
            user: {
                defaultLanguageCode: user.defaultLanguageCode
            }
        })
        setUser(user)
        setAuthenticated(true)
    }

    const signOut = async () => {
        await EntiendoSDK.auth.revoke()
        setAuthenticated(false)
        setUser(null)
        localStorage.removeItem('auth')
    }

    useEffect(() => {
        const subscription = EntiendoSDK.auth.onAuthenticationChanged(newAuth => {
            const auth = getAuthenticationCache() ?? {}
            if (newAuth) {
                setAuthenticationCache({
                    ...auth,
                    accessToken: newAuth.accessToken,
                    refreshToken: newAuth.refreshToken,
                    expires: newAuth.expires
                })
            } else {
                localStorage.removeItem('auth')
                setAuthenticated(false)
                setUser(null)
            }
        })
        const auth = getAuthenticationCache()
        if (auth) {
            EntiendoSDK.auth.setAuthentication({
                accessToken: auth.accessToken,
                refreshToken: auth.refreshToken,
                expires: new Date(auth.expires),
                clientId: process.env.REACT_APP_CLIENT_ID as string,
                clientSecret: process.env.REACT_APP_CLIENT_SECRET as string
            })
            setAuthenticated(true)
            getUser()
                .then(user => {
                    auth.user = {
                        defaultLanguageCode: user.defaultLanguageCode
                    }
                    localStorage.setItem('auth', JSON.stringify({
                        ...auth,
                        user: {
                            defaultLanguageCode: user.defaultLanguageCode 
                        }
                    }))
                    setUser(user)
                })
                .catch(error => console.log(error))
        } else {
            setAuthenticated(false)
        }
        return () => { subscription.remove() }
    }, [])

    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

        } else {
            const handleContextMenu = (e: any) => {
                e.preventDefault()
            }
            document.addEventListener("contextmenu", handleContextMenu)
            return () => {
                document.removeEventListener("contextmenu", handleContextMenu)
            }
        }
    }, [])

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            updateTheme()
        })
        updateTheme()
    }, [])

    const value = {
        authenticated,
        user,
        authenticate,
        updateUser,
        signOut
    }

    return <AuthContext.Provider value={value} children={props.children} />

}

export { AuthProvider }
export default AuthContext
