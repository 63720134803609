import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import EntiendoSDK, { Conversation, Message, UploadMediaResponse, WSOutgoingType } from 'entiendo-javascript-sdk'
import { useNavigate } from 'react-router-dom'
import PouchDB from 'pouchdb'
import useIsFocused from '@/hooks/useIsFocused'
import { v1 as uuid } from 'uuid'
import useAuth from '@/hooks/useAuth'
import useWebSocket from '@/hooks/useWebSocket'
import useConversations from '@/hooks/useConversations'
import Sidebar from '@/components/sidebar'
import ConversationComponent from '@/components/conversation'
import Spinner from '@/components/spinner'
import { notify } from '@/utils/notification'
import styles from '@/styles/chat.module.scss'
import { decryptText, encryptText } from '@/utils/crypto'

const Chat = () => {

    const navigate = useNavigate()
    const { authenticated, user } = useAuth()
    const socket = useWebSocket()
    const { conversations, loading: conversationsLoading, currentConversation, localLanguageCode, remoteLanguageCode, findConversation, updateConversation } = useConversations()
    const isFocused = useIsFocused()
    const [ messages, _setMessages ] = useState<Array<Message>>([])
    const messagesRef = useRef<Array<Message>>([])

    const messagesDB = useMemo(() =>
        currentConversation ? new PouchDB(`messages:${currentConversation._id}`) : null
    , [ currentConversation ])

    const personalLink = useMemo(() =>
        user?.username ? `https://entiendo.chat/u/${user.username.toLowerCase()}` : null
    , [ user?.username ])

    const decryptMessage = useCallback((conversation: Conversation, message: Message): Message => {
        if (conversation.encryption) {
            const { encryption: { key, iv } } = conversation
            if (message.message) {
                message.message = decryptText(message.message, key, iv)
            }
            for (let i = 0; i < message.translations.length; i++) {
                message.translations[i].message = decryptText(message.translations[i].message, key, iv)
            }
        }
        return message
    }, [])

    const saveMessage = async (message: Message) => {
        const db = new PouchDB(`messages:${message.conversationId}`)
        try {
            const dbMessage = await db.get(message._id)
            await db.put({ ...message, _rev: dbMessage._rev })
        } catch (error: any) {
            if (error.status === 404) {
                await db.put(message)
            }
        }
    }

    const setMessages = (messages: Message[]) => {
        messagesRef.current = messages
        _setMessages(messages)
    }

    const addMessage = useCallback(async (message: Message) => {
        if (message.conversationId === currentConversation?._id) {
            const newMessages = [...messagesRef.current]
            const index = newMessages.findIndex(m => (message.handle && m.handle === message.handle) || m._id === message._id)
            if (index >= 0) newMessages[index] = message
            else newMessages.unshift(message)
            setMessages(newMessages)
        }
        if (message._id !== message.handle) {
            await saveMessage(message)
        }
    }, [ currentConversation ])

    const handleMessage = useCallback(async (message: Message) => {
        const conversation = await findConversation(message.conversationId, true)
        if (!conversation) return console.error(`Conversation not found: ${message.conversationId}`)
        const messageDecrypted = decryptMessage(conversation, message)
        addMessage(messageDecrypted)
        updateConversation(message.conversationId, {}, messageDecrypted)
        if (currentConversation && isFocused && message.conversationId === currentConversation?._id) {
            EntiendoSDK.conversation.setSeen(currentConversation._id)
        }
        EntiendoSDK.socket.send({
            type: WSOutgoingType.MESSAGE_ACK,
            data: { _id: message._id }
        })
        const sender = conversation?.users.find(cu => cu.user._id === message.senderId)
        if (sender && sender._id !== user?._id) {
            notify({
                title: sender.user.displayName,
                body: message.message,
                icon: sender.user.pictureUrl
            })
        }
    }, [ user?._id, isFocused, currentConversation, findConversation, updateConversation, addMessage, decryptMessage ])

    const getMessages = useCallback(async () => {
        try {
            if (!messagesDB) return
            const data = await messagesDB.allDocs({ include_docs: true, descending: true })
            setMessages(data.rows.map(r => r.doc as unknown as Message))
        } catch {

        }
    }, [ messagesDB ])

    const sendMessage = async (input?: string, media?: UploadMediaResponse[]) => {
        const handle = uuid()
        try {
            if (!user) throw new Error('User not available')
            if (!currentConversation) throw new Error('No conversation selected')
            const timeSent = new Date()
            let inputEncrypted: string | undefined = input
            if (input && currentConversation.encryption) {
                const { encryption: { key, iv } } = currentConversation
                inputEncrypted = encryptText(input, key, iv)
            }
            const newMessage: Message = {
                _id: handle,
                handle,
                conversationId: currentConversation._id,
                senderId: user._id,
                sourceLanguageCode: localLanguageCode,
                message: input,
                translations: [],
                timeSent: timeSent.toISOString(),
                media: media ? media.map(m => ({
                    _id: m._id,
                    mime: m.mime,
                    url: m.url
                })) : [],
                deleted: false
            } 
            addMessage(newMessage)
            const response = await EntiendoSDK.message.send({
                handle,
                conversationId: currentConversation._id,
                message: inputEncrypted,
                media: media ? media.map(m => m._id) : undefined,
                timeSent
            })
            addMessage(decryptMessage(currentConversation, response.data))
        } catch (error) {
            if (currentConversation) {
                deleteMessage(currentConversation._id, { handle })
            }
        }
    }


    const deleteMessage = async (conversationId: string, { messageId, handle }: { messageId?: string, handle?: string }) => {
        if (conversationId === currentConversation?._id) {
            const newMessages = [...messagesRef.current].filter(m => m.handle !== handle && m._id !== messageId)
            setMessages(newMessages)
        }
        if (messageId) {
            const db = new PouchDB(`messages:${conversationId}`)
            try {
                const dbMessage = await db.get(messageId)
                await db.remove({ _id: messageId, _rev: dbMessage._rev })
            } catch (error: any) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        if (authenticated === true) {
            const unsubscribe = EntiendoSDK.addListener('message', handleMessage)
            return () => {
                unsubscribe()
            }
        }
        else if (authenticated === false) {
            navigate('/auth')
        }
    }, [ authenticated, user, conversations, currentConversation?._id, handleMessage, navigate ])

    useEffect(() => {
        if (authenticated && currentConversation) {
            getMessages()
        }
    }, [ authenticated, currentConversation, getMessages ])

    if (!user) return null

    return (
        <div className={styles.screen}>
            <div className={styles.app}>
                {process.env.REACT_APP_ELECTRON === '1' ? <div className={styles.toolbar}></div> : null}
                <div className={styles.chat}>
                    <div>
                        <Sidebar />
                    </div>
                    <div>
                        {currentConversation !== null ? (
                            <ConversationComponent
                                localLanguageCode={localLanguageCode}
                                remoteLanguageCode={remoteLanguageCode}
                                messages={messages}
                                onPressSend={sendMessage}
                            />
                        ) : (
                            <div className="flex flex-1 h-full justify-center items-center">
                                <div className="text-center">
                                    {personalLink ? <>
                                        <div className="text-2xl font-medium">Your personal link</div>
                                        <div className="text-text-secondary mt-2">Use this link to invite people to chat with you.</div>
                                        <input
                                            type="text"
                                            value={personalLink}
                                            className={styles['personal-link']}
                                            onChange={() => {}}
                                        />
                                    </> : null}
                                </div>
                            </div>
                        )}
                    </div>
                    {conversationsLoading ? <Spinner /> : null}
                </div>
                {!socket.connected && (
                    <div className={styles['alert-bar']}>
                        Connection problem
                    </div>
                )}
            </div>
        </div>
    )

}

export default Chat