import { isToday, isYesterday, isThisWeek, format } from 'date-fns'
import type { Language } from '../language/types'

let is24Hour = true

export function formatRelativeTime(language: Language, date: Date, timeForPrevDays = false): string {
    const timeFormat = is24Hour ? 'HH:mm' : 'h:mm aaa'
    if (isToday(date)) {
        return format(date, timeFormat)
    }
    else if (isYesterday(date)) {
        if (timeForPrevDays) return format(date, `'${language.days.yesterday}' ${timeFormat}`)
        else return language.days.yesterday
    }
    else if (isThisWeek(date)) {
        const day = language.days[format(date, "iiii").toLowerCase()]
        if (timeForPrevDays) return day + ' ' + format(date, timeFormat).toLowerCase()
        else return language.days[format(date, 'iiii').toLowerCase()]
    }
    else {
        if (timeForPrevDays) return format(date, `dd/LL/yy ${timeFormat}`)
        else return format(date, "dd/LL/yy")
    }
}