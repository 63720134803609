import { useCallback, useMemo, useRef, useState } from 'react'
import { ConversationUser } from 'entiendo-javascript-sdk'
import useLanguage from '../../hooks/useLanguage'
import useAuth from '../../hooks/useAuth'
import UserPicture from '../user-picture'
import ContextMenu from '../context-menu'
import CircleButton from '../circle-button'
import { ConversationDoc } from '../../contexts/ConversationsContext'
import { ReactComponent as IconTranslate } from '../../assets/icons/translate.svg'
import { ReactComponent as IconEllipsisV } from '../../assets/icons/ellipsis-v.svg'
import styles from './styles.module.scss'

export type ConversationHeaderProps = {
    conversation: ConversationDoc
    localLanguageCode: string
    remoteLanguageCode: string
    onClickLanguage?(): void
    onClickUser?(user: ConversationUser['user']): void
}

const ConversationHeader = ({ conversation, localLanguageCode, remoteLanguageCode, onClickLanguage = () => {}, onClickUser = () => {} }: ConversationHeaderProps) => {

    const { user } = useAuth()
    const { language } = useLanguage()
    const [ contextMenuVisible, setContextMenuVisible ] = useState(false)
    const menuRef = useRef<any>()

    const { localLanguageName, remoteLanguageName } = useMemo(() => ({
        localLanguageName: language.languages[localLanguageCode],
        remoteLanguageName: language.languages[remoteLanguageCode]
    }), [ language, localLanguageCode, remoteLanguageCode ])

    const otherUser = useMemo(() => {
        if (conversation.type === 'default') {
            const otherUser = conversation.users.find(cu => cu.user._id !== user?._id)?.user
            if (otherUser) return otherUser
        }
        return null
    }, [ user?._id, conversation.type, conversation.users ])
    
    const onClickTitle = useCallback(() => {
        if (otherUser) {
            return onClickUser(otherUser)
        }
    }, [ otherUser, onClickUser ])

    const usersString = useMemo(() =>
        conversation.users
            .filter(cu => cu.user._id !== user?._id)
            .slice(0, 5)
            .map(cu => cu.user.displayName)
            .join(', ')
    , [ conversation.users, user?._id ])

    const menu = useMemo(() =>  [
        {
            key: 'delete',
            name: language.delete
        }
    ], [ language ])

    return (
        <div className={`${styles.header} flex flex-row items-center justify-between`}>
            {conversation !== null && <>
                <div className={`${styles.title} flex flex-1 items-center font-medium`} onClick={onClickTitle}>
                    <UserPicture
                        id={otherUser?._id ?? conversation._id}
                        name={conversation.title}
                        url={conversation.pictureUrl}
                        className="mr-3"
                    />
                    <div>
                        <div className="hidden sm:inline">{conversation.title}</div>
                        {conversation.type === 'group' ?
                            <div className="text-sm text-text-secondary">{usersString}</div> :
                            null
                        }
                    </div>
                </div>
                <div
                    className="flex flex-1 items-center justify-center py-3 text-sm font-medium text-text-secondary hover:text-text-primary transition-colors cursor-default select-none"
                    onClick={onClickLanguage}
                >
                    <span className="hidden md:inline">{localLanguageName}</span>
                    <span className="md:hidden">{localLanguageCode.toUpperCase()}</span>
                    <IconTranslate
                        width={20}
                        height={20}
                        className="mx-2.5"
                    />
                    <span className="hidden md:inline">{conversation.type === 'default' ? remoteLanguageName : 'Various'}</span>
                    <span className="md:hidden">{remoteLanguageCode.toUpperCase()}</span>
                </div>
                <div className="flex flex-1 justify-end">
                    <ContextMenu
                        ref={menuRef}
                        data={menu}
                        offset={{
                            height: 52,
                            width: 0
                        }}
                        onShow={() => setContextMenuVisible(true)}
                        onHide={() => setContextMenuVisible(false)}
                    >
                        <CircleButton
                            active={contextMenuVisible}
                            onClick={() => menuRef.current?.show()}
                        >
                            <IconEllipsisV
                                width={24}
                                height={24}
                            />
                        </CircleButton>
                    </ContextMenu>
                </div>
            </>}
        </div>
    )

}

export default ConversationHeader