import { useMemo } from 'react'
import { generateColorHsl } from '@/utils/user-color'
import styles from './styles.module.scss'

export type UserPictureProps = {
    id?: string
    name?: string
    url?: string
    size?: number
    className?: string
}

const UserPicture = ({ id, name, url, size = 40, className }: UserPictureProps) => {

    const backgroundColor = useMemo(() => id ? generateColorHsl(id, [40,60], [40,50]) : undefined, [ id ])

    const initials = useMemo(() => {
        if (name) {
            const initials = name
                .replace(/[^A-Za-z ]/g, '')
                .split(' ')
                .map(str => str[0])
            if (initials.length > 1) return [[...initials].shift(),[...initials].pop()].join('').toUpperCase()
            else return initials[0].toUpperCase()
        }
        return undefined
    }, [ name ])

    return (
        <div
            className={`${styles.picture} rounded-full bg-center bg-cover ${className}`}
            style={{
                backgroundColor,
                backgroundImage: `url(${url})`,
                minWidth: size,
                width: size,
                height: size
            }}
        >
            {(!url && name) && (
                <div className={styles.initials} style={{ fontSize: size * 0.4 }}>
                    {initials}
                </div>
            )}
        </div>
    )

}

export default UserPicture