import CryptoJS from 'crypto-js'

export function encryptText(text: string, key: string, iv: string): string {
    const cipher = CryptoJS.AES.encrypt(text, CryptoJS.enc.Base64.parse(key), {
        iv: CryptoJS.enc.Base64.parse(iv)
    })
    return cipher.toString()
}

export function decryptText(text: string, key: string, iv: string): string {
    const cipher = CryptoJS.AES.decrypt(text, CryptoJS.enc.Base64.parse(key), {
        iv: CryptoJS.enc.Base64.parse(iv)
    })
    return CryptoJS.enc.Utf8.stringify(cipher)
}