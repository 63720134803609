import { forwardRef, useState, useImperativeHandle } from 'react'
import styles from './styles.module.scss'

export type UploadOverlayProps = {
    onDragLeave?(e: React.DragEvent<HTMLDivElement>): void
    onDrop?(files: File[]): void
}

const UploadOverlay = forwardRef(({ onDragLeave, onDrop }: UploadOverlayProps, ref) => {

    const [ files, setFiles ] = useState<File[]>([])

    useImperativeHandle(ref, () => ({
        clear
    }), [])

    const handleDrop = (event:  React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const dt = event.dataTransfer
        const files = Array.from(dt.files)
        if (onDrop) onDrop(files)
        setFiles(files)
    }

    const clear = () => {
        setFiles([])
    }

    return (
        <div
            className={styles.overlay}
            onDragLeave={onDragLeave}
            onDragOver={e => e.preventDefault()}
            onDrop={handleDrop}
        >
            {files.map((file, index) => (
                <div key={'file-'+index} className={styles.preview}>
                    <img
                        src={URL.createObjectURL(file)}
                        alt="upload"
                    />
                </div>
            ))}
        </div>
    )

})

export default UploadOverlay