import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as IconX } from '../../assets/icons/times.svg'

export type ConversationOverlayProps = {
    onRequestClose(): void
}

const ConversationOverlay = ({ children, onRequestClose }: PropsWithChildren<ConversationOverlayProps>) => {

    return (
        <div className={styles.container}>
            {children}
            <div className="absolute right-4 top-4" onClick={onRequestClose}>
                <IconX
                    fill={'#ffffff'}
                    width={34}
                    height={34}
                />
            </div>
        </div>
    )

}

export default ConversationOverlay