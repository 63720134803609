import { useMemo } from 'react'
import styles from './styles.module.scss'

export type ListItemProps = {
    title: string
    iconComponent?: JSX.Element
    value?: string
    renderValue?(): JSX.Element | string | null
    active?: boolean
    onClick?(): void
}

const ListItem = ({ title, iconComponent, value, renderValue, active = false, onClick }: ListItemProps) => {

    const className = useMemo(() => {
        let cn = styles['list-item']
        if (onClick !== undefined) cn += ' ' + styles.clickable
        if (active) cn += ' ' + styles.active
        return cn
    }, [ active, onClick ])

    return (
        <div
            className={className}
            onClick={onClick}
        >
            {iconComponent !== undefined && (
                <div className={styles.icon}>
                    {iconComponent}
                </div>
            )}
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                {renderValue !== undefined && renderValue()}
                {value !== undefined && <div className={styles.value}>{value}</div>}
            </div>
        </div>
    )

}

export default ListItem