import { useMemo } from 'react'
import { MessageMedia } from 'entiendo-javascript-sdk'
import MessageMediaAudio from './audio'
import styles from './styles.module.scss'

export type MessageMediaProps = {
    media: MessageMedia[]
    localLanguageCode: string
    withMessage: boolean
}

const mimeTypes = {
    audio: ['audio/flac','audio/mp3'],
    image: ['image/gif','image/jpeg','image/png'],
    video: ['video/mp4']
}

const MessageMediaComponent = ({ media, localLanguageCode, withMessage }: MessageMediaProps) => {

    const images = useMemo(() => {
        const data = media.slice(0,4).filter(file => file.mime.startsWith('image') || file.mime.startsWith('video'))
        if (data.length === 3) data.push({ _id: 'none', mime: 'none/none', url: '' })
        return data
    }, [ media ])

    const audio = useMemo(() =>
        media.find(file => file.mime.startsWith('audio')) ?? null
    , [ media ])

    return (
        <div className={`${styles.media} ${withMessage ? styles['with-message'] : ''}`}>
            {images.length > 0 ? (
                <div className={styles['image-container']}>
                    {images.map(file => {
                        if (mimeTypes.image.includes(file.mime)) {
                            return (
                                <div
                                    key={file._id}
                                    className={styles.image}
                                    style={{ backgroundImage: `url(${file.url})` }}
                                >
                                </div>
                            )
                        }
                        else if (mimeTypes.video.includes(file.mime)) {
                            return (
                                <video key={file._id} className={styles.video}>
                                    <source src={file.url} type={file.mime}></source>
                                </video>
                            )
                        }
                        else return null
                    })}
                </div>
            ) : null}
            {audio ? (
                <MessageMediaAudio
                    media={audio}
                    localLanguageCode={localLanguageCode}
                />
            ) : null}
        </div>
    )

}

export default MessageMediaComponent