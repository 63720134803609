let electron: any 

if ("require" in window) {
    electron = window.require("electron")
}

class Electron {

    public isFocused: boolean = true

    public setBadgeCount(count: number) {
        electron?.ipcRenderer.send('badge', count)
    }

    public onfocus = (callback: () => void) => {
        const cb = () => { this.isFocused = true; callback() }
        electron?.ipcRenderer.addListener('focus', cb)
        return () => electron?.ipcRenderer.removeListener('focus', cb)
    }

    public onblur = (callback: () => void) => {
        const cb = () => { this.isFocused = false; callback() }
        electron?.ipcRenderer.addListener('blur', cb)
        return () => electron?.ipcRenderer.removeListener('blur', cb)
    }

}

export default new Electron()