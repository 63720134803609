import React, { useMemo, useState } from 'react'
import useLanguage from '@/hooks/useLanguage'
import useAuth from '@/hooks/useAuth'
import List, { ListItem, ListRenderItemData } from '../list'
import Modal, { ModalActions } from '../modal'
import UserPicture from '../user-picture'
import { ReactComponent as IconAngleLeft } from '../../assets/icons/angle-left.svg'
import { ReactComponent as IconUser } from '../../assets/icons/user.svg'
import { ReactComponent as IconGlobe } from '../../assets/icons/globe.svg'
import { ReactComponent as IconComment } from '../../assets/icons/comment.svg'
import { ReactComponent as IconMobile } from '../../assets/icons/mobile.svg'
import { ReactComponent as IconSignOut } from '../../assets/icons/sign-out.svg'
import styles from './styles.module.scss'

export type SettingsProps = {
    visible: boolean
    onRequestClose?(): void
}

export type Setting = {
    key: string
    title: string
    iconComponent?: JSX.Element
    component?: React.LazyExoticComponent<() => JSX.Element>
    onClick?(): void
}

const fallback = React.lazy(() => import('./screens/fallback'))

const Settings = ({ visible, onRequestClose = () => {} }: SettingsProps) => {

    const { language } = useLanguage()
    const { user, signOut } = useAuth()
    const [ activeIndex, setActiveIndex ] = useState<number | null>(null)
    const [ signOutModalVisible, setSignOutModalVisible ] = useState(false)

    const settings = useMemo<Setting[]>(() => [
        {
            key: 'username',
            title: language.username,
            iconComponent: <IconUser width={20} height={20} />,
            component: React.lazy(() => import('./screens/username'))
        },
        {
            key: 'language',
            title: language.language,
            iconComponent: <IconGlobe width={20} height={20} />,
            component: React.lazy(() => import('./screens/language'))
        },
        {
            key: 'chats',
            title: language.chats,
            iconComponent: <IconComment width={20} height={20} />,
            component: React.lazy(() => import('./screens/chats'))
        },
        {
            key: 'devices',
            title: language.myDevices,
            iconComponent: <IconMobile width={20} height={20} />,
            component: React.lazy(() => import('./screens/devices'))
        },
        {
            key: 'signOut',
            title: language.signOut,
            iconComponent: <IconSignOut width={20} height={20} />,
            onClick: () => setSignOutModalVisible(true)
        }
    ], [ language ])

    const renderItem = ({ item, index }: ListRenderItemData<Setting>) => {
        return (
            <ListItem
                title={item.title}
                iconComponent={item.iconComponent}
                onClick={item.onClick ? item.onClick : () => setActiveIndex(index)}
            />
        )
    }

    const Screen = useMemo(() => {
        if (activeIndex !== null) {
            const setting = settings[activeIndex]
            return setting?.component ? setting.component : fallback
        }
        return fallback
    }, [ settings, activeIndex ])

    const signOutModalActions = useMemo<ModalActions>(() => [
        {
            name: language.cancel,
            primary: true,
            onClick: () => setSignOutModalVisible(false)
        },
        {
            name: language.signOut,
            onClick: () => {
                setSignOutModalVisible(false)
                signOut()
            }
        }
    ], [ language, signOut ])

    const containerClassName = useMemo(() => {
        let cn = styles.container
        if (visible) cn += ' ' + styles.visible
        return cn
    }, [ visible ])

    return (
        <div className={containerClassName}>
            <header className={styles.header}>
                <div className={styles['back-button']} onClick={onRequestClose}>
                    <div className={styles.title}>
                        <div className={styles.icon}>
                            <IconAngleLeft width={20} height={20} />
                        </div>
                        {language.settings}
                    </div>
                </div>
            </header>
            <div className={styles.user}>
                <div className={styles.picture}>
                    <UserPicture
                        id={user?._id}
                        name={user?.username}
                        url={user?.pictureUrl}
                        size={64}
                    />
                </div>
                <div>
                    <span className={styles.username}>{user?.username}</span><br />
                    <span className={styles.phone}>{user?.phone}</span>
                </div>
            </div>
            <List<Setting>
                data={settings}
                renderItem={renderItem}
            />
            {activeIndex !== null && (
                <div className={styles.screen}>
                    <header className={styles.header}>
                        <div className={styles['back-button']} onClick={() => setActiveIndex(null)}>
                            <div className={styles.title}>
                                <div className={styles.icon}>
                                    <IconAngleLeft width={20} height={20} />
                                </div>
                                {settings[activeIndex].title}
                            </div>
                        </div>
                    </header>
                    <React.Suspense fallback>
                        <Screen />
                    </React.Suspense>
                </div>
            )}
            <Modal
                visible={signOutModalVisible}
                title={language.signOut}
                actions={signOutModalActions}
                width={320}
            >
                {language.signOutConfirm}
            </Modal>
        </div>
    )

}

export default Settings