import ListItem, { ListItemProps } from './list-item'
import styles from './styles.module.scss'

export type ListRenderItemData<T = any> = {
    item: T
    index: number
}

export type ListProps<T> = {
    data: Array<T>
    renderItem(item: ListRenderItemData<T>): JSX.Element
    keyExtractor?(item: T): string
}

function List<T = any>({ data, renderItem, keyExtractor }: ListProps<T & { key?: string }>) {

    return (
        <div className={styles.list}>
            {data.map((item, index) =>
                <div key={keyExtractor ? keyExtractor(item) : item.key}>
                    {renderItem({ item, index })}
                </div>
            )}
        </div>
    )

}

export { ListItem }

export type { ListItemProps }

export default List