import { createContext } from 'react'
import RTCRingDisplay from '@/components/rtc-ring-display'

interface RTCProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface RTCContextValue {

}

const RTCContext = createContext<RTCContextValue>({

})

const RTCProvider = ({ children }: RTCProviderProps) => {


    const value = {
        
    }

    return (
        <RTCContext.Provider value={value}>
            {children}
            <RTCRingDisplay />
        </RTCContext.Provider>
    )

}

export { RTCProvider }

export default RTCContext
