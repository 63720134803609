import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import QRCode from 'react-qr-code'
import useAuth from '@/hooks/useAuth'
import EntiendoSDK from 'entiendo-javascript-sdk'
import { getBrowserName } from '@/utils/browser'
import styles from '@/styles/auth.module.scss'
import AppleSignInButton from '@/components/apple-sign-in-button'

const Auth = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { authenticate, authenticated } = useAuth()
    const [ tokenURL, setTokenURL ] = useState<string | null>(null)
    const codeInterval = useRef<NodeJS.Timer>()

    const authWithCode = useCallback(async (code: string) => {
        try {
            const { data: auth } = await EntiendoSDK.auth.authenticate({
                grantType: 'authorization_code',
                code,
                // device: {
                //     vendorId: getBrowserId(),
                //     platform: 'web',
                //     deviceModel: getBrowserName()
                // }
            })
            authenticate({
                accessToken: auth.access_token,
                refreshToken: auth.refresh_token,
                expires: auth.expires
            })
        } catch (error) {
            console.error(error)
        }
    }, [ authenticate ])

    const requestAuthorizationToken = useCallback(async () => {
        try {
            const response = await EntiendoSDK.auth.device.request({
                deviceName: getBrowserName() ?? 'Unknown'
            })
            setTokenURL(`entiendochat://auth?t=${response.data.token}`)
            if (codeInterval.current) clearInterval(codeInterval.current)
            codeInterval.current = setInterval(async () => {
                try {
                    const { data } = await EntiendoSDK.auth.device.getCode(response.data.token)
                    if (data) {
                        clearInterval(codeInterval.current)
                        authWithCode(data.code)
                    }
                } catch (error: any) {
                    if (error.isAxiosError && error.response?.data?.code === 'Forbidden') {
                        // Keep trying
                    } else {
                        clearInterval(codeInterval.current)
                        requestAuthorizationToken()
                    }
                }
            }, 10 * 1000)
        } catch (error) {
            console.error(error)
        }
    }, [ authWithCode ])

    useEffect(() => {
        requestAuthorizationToken()
    }, [ requestAuthorizationToken ])

    useEffect(() => {
        if (authenticated) {
            const state = location.state as any
            if (state?.previousPath) {
                navigate(state.previousPath)
            } else {
                navigate('/')
            }
        }
    }, [ authenticated, navigate, location.state ])

    return (
        <div className={styles['auth-container']}>
            <div className={styles.auth}>
                <AppleSignInButton />
                {tokenURL !== null && (
                    <div>
                        <h2>Sign in with QR code</h2>
                        <div className="flex justify-center mt-8">
                            <QRCode
                                bgColor={'#1e1e21'}
                                fgColor={'#ffffff'}
                                size={256}
                                style={{
                                    height: 'auto',
                                    maxWidth: '60%',
                                    width: '60%'
                                }}
                                value={tokenURL}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <br />
                        <ol>
                            <li>Make sure you have the Entiendo app installed and you're signed in.</li>
                            <li>Scan the QR code with your mobile device.</li>
                            <li>Approve the authorization request.</li>
                        </ol>               
                    </div>
                )}
            </div>
        </div>
    )

}

export default Auth