import { ReactComponent as SendFillIcon } from '../../assets/icons/send-fill.svg'
import styles from './styles.module.scss'

export type SendButtonProps = {
    onClick(): void
    className?: string
}

const SendButton = ({ onClick, className }: SendButtonProps) => {

    return (
        <div
            className={`${styles.button} ${className} flex items-center`}
            onClick={onClick}
        >
            <SendFillIcon />
        </div>
    )

}

export default SendButton