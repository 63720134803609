import { createContext, useEffect, useState } from 'react'
import EntiendoSDK, { WebSocketMessageData, WSOutgoingType } from 'entiendo-javascript-sdk'

interface WebSocketProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface WebSocketContextValue {
   connected: boolean
}

export interface MessageTranslation {
    message: string
    languageCode: string
}

const WebSocketContext = createContext<WebSocketContextValue>({
    connected: false
})

const WebSocketProvider = ({ children }: WebSocketProviderProps) => {

    const [ connected, setConnected ] = useState(false)

    useEffect(() => {
        const subscriptions = [
            EntiendoSDK.socket.onopen(() => setConnected(true)),
            EntiendoSDK.socket.onclose(() => setConnected(false))
        ]
        return () => subscriptions.forEach(unsubscribe => unsubscribe())
    }, [])

    useEffect(() => {
        const unsubscribe = EntiendoSDK.socket.onclose(() => setConnected(false))
        return unsubscribe
    }, [])

    return <WebSocketContext.Provider value={{ connected }} children={children} />

}

export { WebSocketProvider }
export type { WebSocketMessageData, WSOutgoingType }
export default WebSocketContext
