import { useEffect, useMemo, useRef, useState } from 'react'
import { MessageMedia } from 'entiendo-javascript-sdk'
import { ReactComponent as IconPlay } from '../../assets/icons/play.svg'
import { ReactComponent as IconPause } from '../../assets/icons/pause.svg'
import styles from './styles.module.scss'

export type MessageMediaAudioProps = {
    media: MessageMedia
    localLanguageCode: string
}

const MessageMediaAudio = ({ media, localLanguageCode }: MessageMediaAudioProps) => {

    const ref = useRef<HTMLAudioElement | null>(null)
    const interval = useRef<NodeJS.Timer>()
    const [ currentWordIndex, setCurrentWordIndex ] = useState(-1)
    const currentWordIndexRef = useRef(-1)
    const [ isPlaying, setIsPlaying ] = useState(false)

    const transcript = useMemo(() =>
        media.transcript?.length ?
            (media.transcript.find(t => t.languageCode === localLanguageCode) ?? media.transcript[0]) :
                null
    , [ media.transcript, localLanguageCode ])

    const play = async () => {
        await ref.current?.play()
        setIsPlaying(true)
        interval.current = setInterval(() => {
            const ms = (ref.current?.currentTime ?? 0) * 1000
            const index = transcript?.transcript.findIndex(w => ms >= w.start && ms <= w.end) ?? -1
            if (index !== currentWordIndexRef.current) {
                currentWordIndexRef.current = index
                setCurrentWordIndex(index)
            }
        }, 30)
    }

    const stop = () => {
        if (interval.current) {
            clearInterval(interval.current)
        }
        ref.current?.pause()
        setIsPlaying(false)
    }

    const handleEnded = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
        stop()
    }

    useEffect(() => {
        // play()
        // return () => {
        //     stop()
        // }
    }, [])

    return (
        <div className={styles['audio-message']}>
            <audio ref={ref as any} className="hidden" onEnded={handleEnded}>
                <source src={media.url} type={media.mime}></source>
            </audio>
            {isPlaying && (
                <div className={styles.transcript}>
                    {transcript?.transcript.map((word, index) => (
                        <span
                            key={`word-${index}`}
                            className={`${styles['transcript-word']}${index <= currentWordIndex ? ' ' + styles.active : ''}`}
                        >
                            {word.word}{' '}
                        </span>
                    ))}
                </div>
            )}
            <div className={styles.controls}>
                {isPlaying ? (
                    <IconPause
                        width={26}
                        height={26}
                        onClick={stop}
                    />
                ) : (
                    <IconPlay
                        width={26}
                        height={26}
                        onClick={play}
                    />
                )}
            </div>
        </div>
    )

}

export default MessageMediaAudio