import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EntiendoSDK from 'entiendo-javascript-sdk'
import './styles/_global.scss'
import { LanguageProvider } from './contexts/LanguageContext'
import { AuthProvider } from './contexts/AuthContext'
import { AppSettingsProvider } from './contexts/AppSettingsContext'
import { WebSocketProvider } from './contexts/WebSocketContext'
import { RTCProvider } from './contexts/RTCContext'
import { ConversationsProvider } from './contexts/ConversationsContext'
import Auth from './routes/auth'
import Chat from './routes/chat'
import User from './routes/user'

EntiendoSDK.configure({
    environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'dev',
    apiKey: 'UfbRMTvathjLtSYsmAt5vzAlXr3zorXrQTbnOV6iF7z72K3Pp4SeTqrlmhoHlirY',
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET as string
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <LanguageProvider>
        <WebSocketProvider>
            <AuthProvider>
                <AppSettingsProvider>
                    <RTCProvider>
                        <ConversationsProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Chat />} />
                                    <Route path="/auth" element={<Auth />} />
                                    <Route path="/u/:user" element={<User />} />
                                </Routes>
                            </BrowserRouter>
                        </ConversationsProvider>
                    </RTCProvider>
                </AppSettingsProvider>
            </AuthProvider>
        </WebSocketProvider>
    </LanguageProvider>
)
