import { createContext, useRef, useState } from 'react'

interface AppSettingsProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface AppSettingsContextValue {
    appSettings: AppSettings
    setAppSettings(settings: Partial<AppSettings>): void
}

export type AppSettings = {
    showOriginalMessages: boolean
}

const defaultSettings: AppSettings = {
    showOriginalMessages: true
}

const AppSettingsContext = createContext<AppSettingsContextValue>({
    appSettings: defaultSettings,
    setAppSettings: () => {}
})

const AppSettingsProvider = ({ children }: AppSettingsProviderProps) => {

    const getAppSettings = (): AppSettings => {
        const json = localStorage.getItem('app.settings')
        return json ? JSON.parse(json) : defaultSettings
    }

    const appSettingsRef = useRef<AppSettings>(getAppSettings())
    const [ appSettings, _setAppSettings ] = useState<AppSettings>(appSettingsRef.current)
    
    const setAppSettings = (settings: Partial<AppSettings>) => {
        appSettingsRef.current = { ...appSettingsRef.current, ...settings }
        localStorage.setItem('app.settings', JSON.stringify(appSettingsRef.current))
        _setAppSettings(appSettingsRef.current)
    }

    const value = {
        appSettings,
        setAppSettings
    }

    return <AppSettingsContext.Provider value={value} children={children} />

}

export { AppSettingsProvider }

export default AppSettingsContext
