import { useMemo } from 'react'
import styles from './styles.module.scss'

export type CircleButtonProps = {
    children?: any
    size?: number
    active?: boolean
    onClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void
}

const CircleButton = ({ children, size = 44, active, onClick = () => {} }: CircleButtonProps) => {

    const className = useMemo<string>(() => {
        let className: string = styles.button
        if (active) className += ' ' + styles.active
        return className
    }, [ active ])

    return (
        <div
            className={className}
            style={{ width: size, height: size }}
            onClick={onClick}
        >
            {children}
        </div>
    )

}

export default CircleButton