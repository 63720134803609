import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import EntiendoSDK, { User } from 'entiendo-javascript-sdk'
import useAuth from '../hooks/useAuth'
import useConversations from '../hooks/useConversations'
import UserPicture from '../components/user-picture'
import Button from '@/components/button'
import styles from '@/styles/user.module.scss'

const UserPage = () => {

    const navigate = useNavigate()
    const params = useParams()
    const { pathname } = useLocation()
    const { authenticated, user: me } = useAuth()
    const { addConversation, setCurrentConversation } = useConversations()
    const [ user, setUser ] = useState<User | null>(null)

    useEffect(() => {
        if (params.user) {
            EntiendoSDK.user.get(params.user)
                .then(({ data }) => setUser(data))
                .catch(error => {
                    console.error(error)
                })
        }
    }, [ params.user, navigate, pathname ])

    const onClickChat = () => {
        if (authenticated && user) {
            EntiendoSDK.conversation.create([user._id])
                .then(({ data }) => {
                    addConversation(data)
                    setCurrentConversation(data._id)
                    navigate('/')
                })
                .catch(console.error)
        } else {
            navigate('/auth', {
                state: {
                    previousPath: pathname
                }
            })
        }
    }

    const appStoreURL = useMemo(() => ({
        android: 'https://play.google.com/store/apps/details?id=com.entiendochat.app',
        ios: 'https://itunes.apple.com/app/id1645069241'
    }), [])

    return (
        <div className="flex items-center justify-center w-screen h-screen bg-background-primary">
            <div className="w-96 p-12 flex justify-center rounded-3xl bg-background-secondary text-center">
                {user ? (
                    <div className="text-center">
                        <UserPicture
                            id={user._id}
                            name={user.displayName}
                            url={user.pictureUrl}
                            size={160}
                            className="mx-auto"
                        />
                        <div className="text-2xl font-medium mt-3">{user.displayName}</div>
                        {user.username !== user.displayName ? (
                            <div className="text-text-secondary">{user.username}</div>
                        ) : null}
                        <div className="hidden sm:block">
                            {user._id !== me?._id &&
                                <Button
                                    primary
                                    value={`Message ${user.displayName}`}
                                    className="mt-8"
                                    onClick={onClickChat} />
                            }
                        </div>
                        <div className={`${styles['app-download']} sm:hidden`}>
                            <div className={styles['app-instructions']}>
                                Download the Entiendo app to chat with {user.displayName}.
                            </div>
                            <a href={appStoreURL.ios} target="_blank" rel="noreferrer">
                                <img src="https://entiendo.chat/img/appstore.png" className={styles['appstore-button']} width={173} height={52} alt="Download Entiendo from the Apple App Store" />
                            </a>
                            <a href={appStoreURL.android} target="_blank" rel="noreferrer">
                                <img src="https://entiendo.chat/img/googleplay.png" className={styles['appstore-button']} width={173} height={52} alt="Download Entiendo from Google Play" />
                            </a>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )

}

export default UserPage