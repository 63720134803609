import EventEmitter from 'events'

export type AudioRecorderOptions = {
    mimeType?: string
}

class AudioRecorder {

    emitter = new EventEmitter()
    recorder: MediaRecorder | null = null
    type: string = 'audio/webm;codecs=opus'
    isRecording = false
    timer: NodeJS.Timer | null = null
    progress: number = 0

    constructor({ mimeType }: AudioRecorderOptions = {}) {
        if (mimeType) this.type = mimeType
    }

    start = async (callback: (file: File) => void) => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        try {
            this.stop()
            this.recorder = new MediaRecorder(stream, {
                mimeType: this.type,
                bitsPerSecond: 240000
            })
            this.recorder.addEventListener('dataavailable', event => {
                const file = new File([event.data], 'file', { type: this.type })
                callback(file)
            })
            this.recorder.start()
            this.timer = setInterval(() => {
                this.progress++
                this.emitter.emit('progress', this.progress)
            }, 1000)
            this.isRecording = true
        } catch (error) {
            console.error(error)
            this.stop()
        }
    }

    stop = () => {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
            this.progress = 0
        }
        if (this.recorder) {
            this.recorder.stop()
            this.isRecording = false
            this.recorder.stream.getTracks().forEach(track => track.stop())
            this.recorder = null
        }
    }

    onprogress = (callback: (progress: number) => void) => {
        this.emitter.addListener('progress', callback)
        return () => this.emitter.removeListener('progress', callback)
    }

}

export default AudioRecorder