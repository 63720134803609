import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as AppleLogo } from '../../assets/apple.svg'
import styles from './styles.module.scss'

const AppleSignInButton = (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {

    const onScriptLoad = () => {
        // @ts-ignore
        AppleID.auth.init({
            clientId: 'com.entiendochat.web',
            scope: 'name email',
            redirectURI: 'https://app.entiendo.chat',
            // state : '[STATE]',
            nonce: uuidv4(),
            usePopup: true
        })
        document.addEventListener('AppleIDSignInOnSuccess', (event: any) => {
            console.log('AppleIDSignInOnSuccess', event.detail.data)
        })
        document.addEventListener('AppleIDSignInOnFailure', (event: any) => {
             console.log('AppleIDSignInOnFailure', event.detail.error)
        })
    }

    const signIn = async () => {
        try {
            // @ts-ignore
            const data = await AppleID.auth.signIn()
            console.log('response data', data)
       } catch (error) {
            console.error(error)
       }
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        script.async = true
        script.onload = onScriptLoad
        document.body.appendChild(script)
    }, [])

    return <>
        <button
            {...props}
            onClick={signIn}
            className={styles.button + ' ' + props.className}
        >
            <AppleLogo width={17} height={17} />
            Continue with Apple
        </button>
    </>

}

export default AppleSignInButton