import UserBar from '../user-bar'
import ConversationList from '../conversation-list'
import styles from './styles.module.scss'

const Sidebar = () => {

    return (
        <div className={styles.sidebar}>
            <UserBar />
            <ConversationList />
        </div>
    )

}

export default Sidebar