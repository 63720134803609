import { useEffect, useState } from 'react'
import Electron from '../utils/electron'

const useIsFocused = (): boolean => {

    const [ isFocused, setIsFocused ] = useState(Electron.isFocused)

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    useEffect(() => {
        if (process.env.REACT_APP_ELECTRON === '1') {
            const unsubscribe = Electron.onfocus(() => {
                setIsFocused(true)
            })
            return unsubscribe
        } else {
            window.addEventListener('focus', handleFocus)
            return () => {
                window.removeEventListener('focus', handleFocus)
            }
        }
    }, [])

    useEffect(() => {
        if (process.env.REACT_APP_ELECTRON === '1') {
            const unsubscribe = Electron.onblur(() => {
                setIsFocused(false)
            })
            return unsubscribe
        } else {
            window.addEventListener('blur', handleBlur)
            return () => {
                window.removeEventListener('blur', handleBlur)
            }
        }
    }, [])

    return isFocused
    
}

export default useIsFocused