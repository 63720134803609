import { useCallback, useMemo, useRef, useState } from 'react'
import useLanguage from '../../hooks/useLanguage'
import useAuth from '@/hooks/useAuth'
import useConversations from '../../hooks/useConversations'
import UserPicture from '../user-picture'
import styles from './styles.module.scss'
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg'
import { ReactComponent as IconTimes } from '../../assets/icons/times.svg'
import { formatRelativeTime } from '../../utils/time'
import { ConversationDoc } from '../../contexts/ConversationsContext'

const ConversationList = () => {

    const { language } = useLanguage()
    const { user } = useAuth()
    const { conversations: data, currentConversation, setCurrentConversation } = useConversations()
    const [ searchInput, setSearchInput ] = useState<string>('')
    const [ searchInputFocused, setSearchInputFocused ] = useState(false)
    const searchInputRef = useRef<any>()

    const sortConversations = useCallback((conversations: ConversationDoc[]): ConversationDoc[] => {
        return conversations
            .sort((a, b) => (b.lastMessageTime ?? '').localeCompare(a.lastMessageTime ?? ''))
            .filter(c => {
                if (searchInput.length) {
                    if (c.title?.toLowerCase().includes(searchInput)) return true
                    else return false
                }
                if (c.type === 'group' || c.lastMessageText) return true
                return false
            })
    }, [ searchInput ])

    const sorted = useMemo<ConversationDoc[]>(() =>
        sortConversations(data)
    , [ data, sortConversations ])

    const onClickClearSearch = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event.stopPropagation()
        setSearchInput('')
        searchInputRef.current?.clear()
        searchInputRef.current?.blur()
    }

    return (
        <>
            <div className={styles.search}>
                <div className="relative">
                    <input
                        ref={searchInputRef}
                        type="text"
                        onChange={({ target }) => setSearchInput(target.value.toLowerCase())}
                        className={`${styles.input} w-full`}
                        onFocus={() => setSearchInputFocused(true)}
                        onBlur={() => setSearchInputFocused(false)}
                    />
                    {searchInputFocused ? 
                        <IconTimes
                           fill={'#ffffff'}
                           className="ml-4 absolute top-3 right-3 opacity-50"
                           width={22}
                           height={22}
                           onClick={onClickClearSearch} /> :
                        <IconSearch
                            fill={'#ffffff'}
                            className="ml-4 absolute top-3 right-3 pointer-events-none opacity-50"
                            width={22}
                            height={22} />
                    }
                </div>
            </div>
            <div className={styles.list}>
                {sorted.map(conversation => {
                    const otherUser = conversation.type === 'default' ?
                        conversation.users.find(cu => cu.user._id !== user?._id) :
                        undefined
                    return (
                        <div
                            key={conversation._id}
                            onClick={() => setCurrentConversation(conversation._id)}
                            className={`${styles.conversation} w-full ${conversation._id === currentConversation?._id ? styles.active : ''}`}
                        >
                            <UserPicture
                                id={otherUser?._id ?? conversation._id}
                                name={conversation.title}
                                url={conversation.pictureUrl}
                            />
                            <div className="hidden md:block ml-3 flex-1 overflow-hidden">
                                <div className="flex flex-row justify-between font-medium">
                                    <div>{conversation.title}</div>
                                    {conversation.lastMessageTime ?
                                        <div className={styles.time}>{formatRelativeTime(language, new Date(conversation.lastMessageTime))}</div> : null}
                                </div>
                                <div className={styles['last-message']}>
                                    <div>{conversation.lastMessageText}</div>
                                    {conversation.badgeCount ? (
                                        <div className={styles.badge}>{conversation.badgeCount}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )

}

export default ConversationList