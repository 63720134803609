import { createContext, useMemo, useState } from 'react'
import { getLanguage } from '../utils/language'
import type { Language } from '../language/types'

interface LanguageProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface LanguageContextValue {
    language: Language
    languageCode: string
    systemLanguageCode: string
    getLanguage(code: string): Language | undefined
    setLanguage(code: string): void
}

const defaultLanguageCode = 'en'
const defaultLanguage = getLanguage(defaultLanguageCode) as Language

const LanguageContext = createContext<LanguageContextValue>({
    language: defaultLanguage,
    languageCode: defaultLanguageCode,
    systemLanguageCode: defaultLanguageCode,
    getLanguage: () => undefined,
    setLanguage: () => {}
})

const LanguageProvider = ({ children }: LanguageProviderProps) => {

    const userLanguageCode = useMemo<string | null>(() => {
        const json = localStorage.getItem('auth')
        const auth = json ? JSON.parse(json) : null
        if (auth?.user) {
            return auth.user.defaultLanguageCode
        }
        return null
    }, [])

    const [ currentLanguage, setCurrentLanguage ] = useState<any>({
        language: getLanguage(userLanguageCode ?? defaultLanguageCode) ?? defaultLanguage,
        code: userLanguageCode ?? defaultLanguageCode
    })

    const setLanguage = (languageCode: string) => {
        if (languageCode === 'system') {
            setCurrentLanguage({
                language: getLanguage(defaultLanguageCode),
                code: defaultLanguageCode
            })
        } else {
            setCurrentLanguage({
                language: getLanguage(languageCode),
                code: languageCode
            })
        }
    }

    const value: LanguageContextValue = {
        language: currentLanguage.language,
        languageCode: currentLanguage.code,
        systemLanguageCode: defaultLanguageCode,
        getLanguage,
        setLanguage
    }

    return <LanguageContext.Provider value={value} children={children} />

}

export { LanguageProvider }
export default LanguageContext