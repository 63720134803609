import { useCallback, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styles from './styles.module.scss'

export type ContextMenuItem = {
    key: string
    name: string
    onClick?(): void
}

export type ContextMenuProps = {
    children?: any
    data: ContextMenuItem[]
    offset?: { height: number, width: number }
    onShow?(): void
    onHide?(): void
}

const ContextMenu = forwardRef(({ children, data, offset, onShow = () => {}, onHide = () => {} }: ContextMenuProps, ref) => {

    const [ visible, setVisible ] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => setVisible(true),
        hide: () => setVisible(false)
    }), [])

    const handleWindowClick = useCallback((event: MouseEvent) => {
        event.stopPropagation()
        if (visible) setVisible(false)
    }, [ visible ])

    useEffect(() => {
        if (visible) onShow()
        else onHide()
        setTimeout(() => {
            window.document.addEventListener('click', handleWindowClick)
        }, 100)
        return () => {
            window.document.removeEventListener('click', handleWindowClick)
        }
    }, [ visible, handleWindowClick, onHide, onShow ])

    return (
        <div className={styles.container}>
            {children}
            {visible && (
                <div
                    className={styles.menu}
                    style={{
                        top: offset?.height ?? 0,
                        right: offset?.width ?? 0
                    }}
                >
                    {data.map(item => (
                        <div key={item.key} onClick={item.onClick}>
                            {item.name}
                        </div> 
                    ))}
                </div>
            )}
        </div>
    )

})

export default ContextMenu