import { useEffect, useState } from 'react'
import UserPicture from '../user-picture'
import EntiendoSDK, { User, RTCDescriptionData } from 'entiendo-javascript-sdk'
import styles from './styles.module.scss'

type RTCDescription = RTCDescriptionData & {
    user: User
}

const RTCRingDisplay = () => {

    const [ description, setDescription ] = useState<RTCDescription | null>(null)

    useEffect(() => {
        const onDescription = async (data: RTCDescriptionData) => {
            if (data.description.type === 'offer') {
                try {
                    const { data: user } = await EntiendoSDK.user.get(data.userId)
                    setDescription({ ...data, user })
                } catch (error) {
                    console.error(error)
                }
            }
        }
        const unsubscribe = EntiendoSDK.on('rtc_description', onDescription)
        return () => { unsubscribe() }
    }, [])

    if (!description) return null

    return (
        <div className={styles['ring-display-container']}>
            <div className={styles['ring-display']}>
                <div className={styles.caller}>
                    <UserPicture
                        id={description.user._id}
                        name={description.user.displayName}
                        url={description.user.pictureUrl}
                        size={48}
                    />
                    <div className={styles['caller-name']}>
                        {description.user.displayName}
                    </div>
                </div>
                <div className={styles.actions}>
                    <div className={styles.button + ' ' + styles['button-answer']}></div>
                    <div className={styles.button + ' ' + styles['button-decline']}></div>
                </div>
            </div>
        </div>
    )

}

export default RTCRingDisplay