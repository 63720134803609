import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import EntiendoSDK, { Conversation, Message } from 'entiendo-javascript-sdk'
import PouchDB from 'pouchdb'
import Electron from '../utils/electron'
import useAuth from '../hooks/useAuth'
import useIsFocused from '../hooks/useIsFocused'

declare var browser: any

interface ConversationsProviderProps {
    children?: JSX.Element | JSX.Element[]
}

export interface ConversationsContextValue {
    conversationsDB: PouchDB.Database<ConversationDoc>
    conversations: ConversationDoc[]
    currentConversation: ConversationDoc | null
    localLanguageCode: string
    remoteLanguageCode: string
    loading: boolean
    badgeCount: number
    addConversation(conversation: Conversation): void
    updateConversation(conversationId: string, changes: Partial<Omit<ConversationDoc, '_id'>>, message?: Message): void
    findConversation(conversationId: string, getRemote: boolean): Promise<ConversationDoc | undefined>
    setCurrentConversation(conversationId: string): void
    resetBadgeCount(conversationId: string): void
}

export type ConversationDoc = Conversation & {
    lastMessageText?: string
    badgeCount?: number
}

const ConversationsContext = createContext<ConversationsContextValue>({ } as any)

const ConversationsProvider = (props: ConversationsProviderProps) => {

    const { authenticated, user } = useAuth()
    const isFocused = useIsFocused()
    const [ conversations, _setConversations ] = useState<ConversationDoc[]>([])
    const conversationsRef = useRef<ConversationDoc[]>([])
    const [ currentConversationId, setCurrentConversationId ] = useState<string | null>(null)
    const [ loading, setLoading ] = useState(true)

    const conversationsDB = useMemo<PouchDB.Database<ConversationDoc>>(() => new PouchDB('conversations'), [])

    const currentConversation = useMemo(() =>
        currentConversationId ? (conversations.find(c => c._id === currentConversationId) ?? null) : null
    , [ conversations, currentConversationId ])

    const localLanguageCode = useMemo(() =>
        currentConversation?.users.find(cu => cu.user._id === user?._id)?.languageCode ?? 'en'
    , [ user?._id, currentConversation ])

    const remoteLanguageCode = useMemo(() =>
        currentConversation?.users.find(cu => cu.user._id !== user?._id)?.languageCode ?? 'Various'
    , [ user?._id, currentConversation ])

    const totalBadgeCount = (c: ConversationDoc[]) => (
        c.map(c => c.badgeCount).filter(c => c) as number[]
    ).reduce((a, b) => a + b, 0)

    const badgeCount = useMemo(() => totalBadgeCount(conversations), [ conversations ])

    const saveConversation = useCallback(async (conversation: Conversation): Promise<ConversationDoc> => {
        try {
            const dbConversation = await conversationsDB.get(conversation._id)
            await conversationsDB.put({ ...dbConversation, ...conversation, _rev: dbConversation._rev })
            return { ...dbConversation, ...conversation }
        } catch (error: any) {
            if (error.status === 404) {
                await conversationsDB.put(conversation)
                return conversation
            } else {
                throw error
            }
        }
    }, [ conversationsDB ])

    const addConversation = useCallback(async (conversation: Conversation) => {
        const conversationDoc = await saveConversation(conversation)
        const newConversations = [...conversationsRef.current]
        const index = newConversations.findIndex(m => m._id === conversationDoc._id)
        if (index >= 0) newConversations[index] = conversationDoc
        else newConversations.unshift(conversationDoc)
        setConversations(newConversations)
    }, [ saveConversation ])
    
    const setConversations = (conversations: ConversationDoc[]) => {
        conversationsRef.current = conversations
        _setConversations(conversationsRef.current)
    }

    const updateConversation = useCallback(async (conversationId: string, changes: Partial<Omit<ConversationDoc, '_id'>>, message?: Message) => {
        const newConversations = [...conversationsRef.current]
        const index = newConversations.findIndex(m => m._id === conversationId)
        if (index >= 0) {
            const newConversation = { ...newConversations[index], ...changes }
            newConversations[index] = newConversation
            if (
                message && (
                    (newConversation.lastMessageTime && message.timeSent.localeCompare(newConversation.lastMessageTime)) ||
                    newConversation.lastMessageTime === undefined
                )
            ) {
                let text = ''
                if (!message.deleted) {
                    const languageCode = newConversation.users.find(cu => cu._id === user?._id)?.languageCode
                    text = message.translations.find(t => t.languageCode === languageCode)?.message ?? message.message ?? ''
                    if (message.media.length) {
                        text = text ? text : ''
                        if (message.media[0].mime.startsWith('image/')) text = '📷 ' + text
                        else if (message.media[0].mime.startsWith('video/')) text = '📹 ' + text
                        else if (message.media[0].mime.startsWith('audio/')) text = '🎤 ' + text
                    }
                }
                newConversations[index].lastMessageTime = message.timeSent
                newConversations[index].lastMessageText = text
                if (
                    message.senderId !== user?._id &&
                    (!isFocused || message.conversationId !== currentConversation?._id)
                ) {
                    newConversations[index].badgeCount = (newConversations[index].badgeCount ?? 0) + 1
                    const badgeCount = totalBadgeCount(newConversations)
                    Electron.setBadgeCount(badgeCount)
                    if (typeof browser !== 'undefined' && typeof browser.browserAction !== 'undefined') {
                        browser.browserAction.setBadgeText({
                            text: badgeCount
                        })
                    }
                }
            }
            setConversations(newConversations)
            await saveConversation(newConversation)
        }
    }, [ currentConversation?._id, user?._id, isFocused, saveConversation ])

    const findConversation = async (conversationId: string, getRemote: boolean): Promise<ConversationDoc | undefined> => {
        let conversation = conversationsRef.current.find(c => c._id === conversationId)
        if (!conversation && getRemote) {
            const { data } = await EntiendoSDK.conversation.get(conversationId)
            if (data) {
                conversation = data as ConversationDoc
                addConversation(data)
            }
        }
        return conversation
    }

    const getConversations = useCallback(async () => {
        try {
            setLoading(true)
            const data = await conversationsDB.allDocs<ConversationDoc>({ include_docs: true, descending: true })
            setConversations(data.rows.map(r => r.doc as ConversationDoc))
            const response = await EntiendoSDK.conversation.getAll()
            response.data.forEach(addConversation)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [ conversationsDB, addConversation ])

    const resetBadgeCount = useCallback(async (conversationId: string) => {
        await updateConversation(conversationId, { badgeCount: 0 })
        const badgeCount = totalBadgeCount(conversationsRef.current)
        Electron.setBadgeCount(badgeCount)
        if (typeof browser !== 'undefined' && typeof browser.browserAction !== 'undefined') {
            browser.browserAction.setBadgeText({
                text: badgeCount
            })
        }
    }, [ updateConversation ])

    useEffect(() => {
        if (authenticated) {
            getConversations()
                .then(() => {
                    EntiendoSDK.socket.connect()
                })
        }
        else if (authenticated === false) {
            setCurrentConversationId(null)
            setConversations([])
            conversationsDB.allDocs()
                .then(data => {
                    const ids = data.rows.map(row => row.id)
                    for (const id of ids) {
                        const messagesDB = new PouchDB(`messages:${id}`)
                        messagesDB.destroy() 
                    }
                    conversationsDB.destroy()
                })
                .catch(console.error)
        }
    }, [ authenticated, conversationsDB, getConversations ])

    useEffect(() => {
        if (authenticated) {
            const unsubscribe = EntiendoSDK.addListener('conversation', addConversation)
            return () => {
                unsubscribe()
            }
        }
    }, [ authenticated, user, conversations, isFocused, addConversation ])

    useEffect(() => {
        if (currentConversation?._id && isFocused) {
            EntiendoSDK.conversation.setSeen(currentConversation._id)
            resetBadgeCount(currentConversation._id)
        }
    }, [ currentConversation?._id, isFocused, resetBadgeCount ])

    const value = {
        conversationsDB,
        conversations,
        currentConversation,
        localLanguageCode,
        remoteLanguageCode,
        loading,
        badgeCount,
        addConversation,
        updateConversation,
        findConversation,
        setCurrentConversation: (conversationId: string) => setCurrentConversationId(conversationId),
        resetBadgeCount
    }

    return <ConversationsContext.Provider value={value} children={props.children} />

}

export { ConversationsProvider }
export default ConversationsContext
