import { useMemo, useState } from 'react'
import emoji from '../../assets/emoji.json'
import styles from './styles.module.scss'

export type Emoji = {
    emoji: string
    description: string
    category: string
    aliases: string[]
    tags: string[]
    unicode_version: string
    ios_version: string
    skin_tones?: boolean
}

export type EmojiPickerProps = {
    visible: boolean
    onSelect(emoji: Emoji): void
}

const EmojiPicker = ({ visible, onSelect }: EmojiPickerProps) => {

    const categories = useMemo(() => Array.from(new Set([...emoji.map(e => e.category)])), [])
    
    const [ activeCategory, setActiveCategory ] = useState(categories[0])

    const filtered = useMemo(() =>
        emoji.filter(e => e.category === activeCategory)
    , [ activeCategory ])

    return (
        <div className={`${styles.picker} flex flex-col h-0 ${visible ? `${styles.visible} h-80` : ''} overflow-hidden select-none`}>
            <div className="flex flex-row pl-4">
                {categories.map(cat => (
                    <div
                        key={cat}
                        className={`${styles.category} ${cat === activeCategory ? styles.active : ''} pr-5 pt-3 pb-2 font-medium text-sm cursor-default`}
                        onClick={() => setActiveCategory(cat)}
                    >
                        {cat.split(' ')[0]}
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap content-start w-full overflow-y-scroll px-3 pb-3 text-2xl">
                {filtered.map(item => (
                    <div
                        key={item.emoji}
                        className="p-1 cursor-default select-none"
                        onClick={() => onSelect(item)}
                    >
                        {item.emoji}
                    </div>
                ))}
            </div>
        </div>
    )

}

export default EmojiPicker