// import supportedLanguages from '@assets/supported-languages.json'
import type { Language } from '../language/types'

// export type LanguageInfo = {
//     code: string
//     locale: string
//     name: string
//     localName: string
// }

const languages: { [key: string]: any } = {
    bg: (): Language => require('../language/bg.json'),
    da: (): Language => require('../language/da.json'),
    de: (): Language => require('../language/de.json'),
    el: (): Language => require('../language/el.json'),
    en: (): Language => require('../language/en.json'),
    es: (): Language => require('../language/es.json'),
    fr: (): Language => require('../language/fr.json'),
    hi: (): Language => require('../language/hi.json'),
    it: (): Language => require('../language/it.json'),
    ja: (): Language => require('../language/ja.json'),
    nl: (): Language => require('../language/nl.json'),
    no: (): Language => require('../language/no.json'),
    pl: (): Language => require('../language/pl.json'),
    pt: (): Language => require('../language/pt.json'),
    ro: (): Language => require('../language/ro.json'),
    ru: (): Language => require('../language/ru.json'),
    sv: (): Language => require('../language/sv.json'),
    tr: (): Language => require('../language/tr.json'),
    uk: (): Language => require('../language/uk.json'),
    zh: (): Language => require('../language/zh.json')
}

export function getLanguage(code: string): Language | undefined {
    return languages[code] ? languages[code]() : undefined
}

// export function getLanguageInfo(code: string): LanguageInfo | undefined {
//     return supportedLanguages.find(l => l.code === code || l.locale === code)
// }

export { Language }