import { useEffect, useRef, useState, useMemo } from 'react'
import EntiendoSDK, { ConversationUser, Message, UploadMediaResponse } from 'entiendo-javascript-sdk'
import { isSameDay, isToday, differenceInMinutes } from 'date-fns'
import useLanguage from '@/hooks/useLanguage'
import useAuth from '@/hooks/useAuth'
import useConversations from '@/hooks/useConversations'
import ConversationHeader from '../conversation-header'
import MessageComponent from '../message'
import ConversationInput from '../conversation-input'
import UploadOverlay from '../upload-overlay'
import LanguageSelect from '@/components/language-select'
import ConversationOverlay from '../conversation-overlay'
import UserProfile from '../user-profile'
import { formatRelativeTime } from '@/utils/time'
import styles from './styles.module.scss'

export type ConversationProps = {
    localLanguageCode: string
    remoteLanguageCode: string
    messages: Array<Message>
    onPressSend(input?: string, media?: UploadMediaResponse[]): void
}

const Conversation = ({ localLanguageCode, remoteLanguageCode, messages, onPressSend }: ConversationProps) => {

    const { language } = useLanguage()
    const { user } = useAuth()
    const { currentConversation, updateConversation } = useConversations()
    const conversation = currentConversation!
    const uploadRef = useRef<any>()
    const [ dropEnabled, setDropEnabled ] = useState(false)
    const [ files, setFiles ] = useState<File[]>([])
    const [ selectedUser, setSelectedUser ] = useState<ConversationUser['user'] | null>(null)
    const [ languageSelectVisible, setLanguageSelectVisible ] = useState(false)

    useEffect(() => {
        if (selectedUser) setSelectedUser(null)
        const unsubscribe = EntiendoSDK.addListener('user_is_typing', data => {
            if (data.conversationId === conversation._id) {
                console.log('user is typing...')
            }
        })
        return () => {
            unsubscribe()
        }
    }, [ conversation._id, selectedUser ])
    
    const sections = useMemo<{ title: string, data: Message[] }[]>(() => {
        const sections: { title: string, data: Message[] }[] = []
        const _messages = messages.sort((a, b) => b.timeSent.localeCompare(a.timeSent))
        let prevSectionTime: Date = new Date()
        if (_messages.length > 0) {
            for (let i = 0; i < _messages.length; i++) {
                if (!sections.length) {
                    const timeSent = new Date(_messages[i].timeSent)
                    sections.push({ title: formatRelativeTime(language, timeSent, true), data: [] })
                    prevSectionTime = timeSent
                }
                else {
                    const timeSent = new Date(_messages[i].timeSent)
                    const prevMessageTime = new Date(_messages[i - 1].timeSent)
                    const thisMessageTime = new Date(_messages[i].timeSent)
                    /*
                        Make sure day is not the same as the previous section header,
                        unless it's today, then we w[ant to show multiple timestamps.
                    */
                    if (!isSameDay(prevSectionTime, thisMessageTime) || isToday(thisMessageTime)) {
                        /*
                            Split timestamps by 10 minutes
                        */
                        if (differenceInMinutes(prevMessageTime, thisMessageTime) >= 10) {
                            sections.push({ title: formatRelativeTime(language, timeSent, true), data: [] })
                            prevSectionTime = timeSent
                        }
                    }
                }
                (sections[sections.length - 1].data as any).push(_messages[i])
            }
        }
        return sections
    }, [ messages, language ])

    const handleDragEnter = () => {
        if (!dropEnabled) setDropEnabled(true)
    }

    const handleDragLeave = () => {
        if (dropEnabled) setDropEnabled(false)
    }

    const handlePressSend = async (input?: string, media: UploadMediaResponse[] = []) => {
        try {
            if (media.length) return onPressSend(undefined, media) // audio messages
            let _media: UploadMediaResponse[] = []
            if (files.length) {
                const response = await EntiendoSDK.media.upload(files, localLanguageCode)
                _media = response.data
            }
            setFiles([])
            setDropEnabled(false)
            uploadRef.current?.clear()
            onPressSend(input, _media)
        } catch (error) {
            console.log(error)
        }
    }

    const onSelectLanguage = async (code: string) => {
        const oldConversation = conversation
        const newConversation = conversation
        for (let i = 0; i < newConversation.users.length; i++) {
            if (newConversation.users[i].user._id === user?._id) {
                newConversation.users[i] = {
                    ...newConversation.users[i],
                    languageCode: code
                }
            }
        }
        try {
            // setLoading(true)
            updateConversation(newConversation._id, { users: newConversation.users })
            await EntiendoSDK.conversation.updateLanguage(conversation._id, code)
            setLanguageSelectVisible(false)
        } catch {
            updateConversation(newConversation._id, { users: oldConversation.users })
        } finally {
            // setLoading(false)
        }
    }
    
    if (!user) return null

    return (
        <div
            className={styles.container}
            onDragEnter={handleDragEnter}
        >
            <ConversationHeader
                conversation={conversation}
                localLanguageCode={localLanguageCode}
                remoteLanguageCode={remoteLanguageCode}
                onClickLanguage={() => setLanguageSelectVisible(true)}
                onClickUser={setSelectedUser}
            />
            <div className={styles.messages}>
                {sections.map((section, index) => (
                    <section key={'section-'+index} className={`${styles.section} py-3`}>
                        {section.data.map((message, index) => (
                            <MessageComponent
                                key={message._id}
                                conversation={conversation}
                                message={message} 
                                localLanguageCode={localLanguageCode}
                                isSender={user._id === message.senderId}
                                prevIsSender={message.senderId === section.data[index + 1]?.senderId}
                                nextIsSender={message.senderId === section.data[index - 1]?.senderId}
                            />
                        ))}
                        <header className="py-3 text-sm text-center text-text-secondary">
                            {section.title}
                        </header>
                    </section>
                ))}
            </div>
            <footer className={styles.inputContainer}>
                <ConversationInput
                    conversationId={conversation._id}
                    localLanguageCode={localLanguageCode}
                    onPressSend={handlePressSend}
                />
            </footer>
            {dropEnabled ?
                <UploadOverlay
                    ref={uploadRef}
                    onDragLeave={handleDragLeave}
                    onDrop={setFiles}
                /> : null
            }
            {selectedUser !== null && (
                <ConversationOverlay onRequestClose={() => setSelectedUser(null)}>
                    <UserProfile user={selectedUser} />
                </ConversationOverlay>
            )}
            {languageSelectVisible && (
                <ConversationOverlay onRequestClose={() => setLanguageSelectVisible(false)}>
                    <LanguageSelect
                        currentLanguageCode={localLanguageCode}
                        onSelect={onSelectLanguage}
                    />
                </ConversationOverlay>
            )}
        </div>
    )

}

export default Conversation