import { useEffect, useState } from 'react'
import EntiendoSDK, { Language } from 'entiendo-javascript-sdk'
import useLanguage from '../../hooks/useLanguage'

export type LanguageSelectProps = {
    currentLanguageCode?: string
    onSelect(code: string): void
}

const LanguageSelect = ({ currentLanguageCode, onSelect }: LanguageSelectProps) => {

    const { languageCode } = useLanguage()
    const [ languages, setLanguages ] = useState<Language[]>([])

    useEffect(() => {
        EntiendoSDK.language.getAll({ language: languageCode })
            .then(({ data }) => setLanguages(data))
            .catch(console.log)
    }, [ languageCode ])

    return (
        <div className="p-20 overflow-auto">
            <div className="flex flex-wrap">
                {languages.map(language => (
                    <div
                        key={language.code}
                        className={`
                            text-text-secondary
                            sm:w-1/2
                            md:w-1/3
                            lg:w-1/4
                            font-semibold
                            text-2xl
                            py-2.5
                            cursor-default
                            hover:text-text-primary
                            transition-colors
                            select-none
                            ${language.code === currentLanguageCode ? 'text-text-primary' : ''}
                        `}
                        onClick={() => onSelect(language.code)}
                    >
                        {language.name}
                    </div>
                ))}
            </div>
        </div>
    )

}

export default LanguageSelect