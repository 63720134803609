import { useState } from 'react'
import useAuth from '../../hooks/useAuth'
import Settings from '../settings'
import UserPicture from '../user-picture'
import CircleButton from '../circle-button'
import { ReactComponent as IconEllipsisV } from '../../assets/icons/ellipsis-v.svg'
import styles from './styles.module.scss'

const UserBar = () => {

    const { user, } = useAuth()
    const [ settingsVisible, setSettingsVisible ] = useState(false)

    const toggleSettings = () => setSettingsVisible(!settingsVisible)

    if (!user) return null

    return (
        <div className={styles.bar}>
            <div className={styles.user}>
                <UserPicture
                    id={user._id}
                    name={user.username}
                    url={user.pictureUrl}
                />
                <div className="hidden md:block ml-3 font-medium">
                    {user.username}
                </div>
            </div>
            <div className="flex">
                {/* <CircleButton
                    active={contextMenuVisible}
                    onClick={() => menuRef.current?.show()}
                >
                    <IconUsers
                        fill={'#ffffff'}
                        width={24}
                        height={24}
                    />
                </CircleButton> */}
                <CircleButton
                    active={settingsVisible}
                    onClick={toggleSettings}
                >
                    <IconEllipsisV
                        fill={'#ffffff'}
                        width={24}
                        height={24}
                    />
                </CircleButton>
            </div>
            <Settings
                visible={settingsVisible}
                onRequestClose={() => setSettingsVisible(false)}
            />
        </div>
    )

}

export default UserBar