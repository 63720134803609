import { useMemo } from 'react'
import { Conversation, Message } from 'entiendo-javascript-sdk'
import useAppSettings from '@/hooks/useAppSettings'
import MessageMedia from './media'
import { generateColorHsl } from '@/utils/user-color'
import styles from './styles.module.scss'

export type MessageProps = {
    conversation: Conversation
    message: Message
    localLanguageCode: string
    isSender: boolean
    prevIsSender: boolean
    nextIsSender: boolean
}

const MessageComponent = ({ conversation, message, localLanguageCode, isSender, prevIsSender, nextIsSender }: MessageProps) => {

    const { appSettings } = useAppSettings()
    
    const sender = useMemo(() =>
        conversation.users.find(cu => cu.user._id === message.senderId)
    , [ message.senderId, conversation.users ])

    const senderColor = useMemo(() =>
        sender ? generateColorHsl(sender._id, [40,60], [40,50]) : undefined
    , [ sender ])

    const top = useMemo(() => {
        if (appSettings.showOriginalMessages) {
            return isSender ?
                message.translations.find(t => t.languageCode !== localLanguageCode)?.message :
                message.message
        }
        return undefined
    }, [ message, isSender, localLanguageCode, appSettings.showOriginalMessages ])

    const bottom = useMemo(() => {
        let text: string | undefined
        if (isSender) text = message.message
        else text = message.translations.find(t => t.languageCode === localLanguageCode)?.message ?? message.message
        if (text) text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
        return text
    }, [ message, isSender, localLanguageCode ])

    const className = useMemo(() => {
        let className = styles.messageBubble
        if (isSender) className += ' ' + styles.self
        if (bottom?.match(/^([\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])$/)) {
            className += ' text-3xl'
        }
        return className
    }, [ isSender, bottom ])

    const [ borderTopLeftRadius, borderTopRightRadius, borderBottomRightRadius, borderBottomLeftRadius ] = useMemo(() => [
        (isSender || (!isSender && !prevIsSender)) ? 22 : 0,
        ((isSender && !prevIsSender) || !isSender) ? 22 : 0,
        ((isSender && !nextIsSender) || !isSender) ? 22 : 0,
        (isSender || (!isSender && !nextIsSender)) ? 22 : 0
    ], [ isSender, nextIsSender, prevIsSender ])

    const media = useMemo(() =>
        message.media.length ?
            <MessageMedia
                media={message.media}
                localLanguageCode={localLanguageCode}
                withMessage={bottom !== undefined}
            /> : null
    , [ message.media, bottom, localLanguageCode ])

    return (
        <div key={message._id} className={className}>
            <div
                className={styles.bubble}
                style={{
                    borderTopLeftRadius,
                    borderTopRightRadius,
                    borderBottomLeftRadius,
                    borderBottomRightRadius
                }}
            >
                {(conversation.type === 'group' && !isSender) && (
                    <div className={styles.sender} style={{ color: senderColor }}>
                        {sender?.user.displayName}
                    </div>
                )}
                {message.deleted ? (
                    <div className={styles.deleted}>
                        Message deleted
                    </div>
                ) : <>
                    {media}
                    {(top && top !== bottom) && (
                        <div className={styles.top}>
                            {top}
                        </div>
                    )}
                    {bottom ? (
                        <div className={styles.bottom} dangerouslySetInnerHTML={{ __html: bottom }}></div>
                    ) : null}
                </>}
            </div>
        </div>
    )

}

export default MessageComponent