import styles from './styles.module.scss'

const Spinner = () => {
    return (
        <div className={styles.spinner}>
            <div className={styles['lds-spinner']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Spinner