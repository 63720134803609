import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import EntiendoSDK, { MessageMedia } from 'entiendo-javascript-sdk'
import useLanguage from '../../hooks/useLanguage'
import EmojiPicker, { Emoji } from '../emoji-picker'
import SendButton from '../send-button'
import AudioRecorder from '@/utils/audio-recorder'
import { ReactComponent as IconSmile } from '../../assets/icons/smile.svg'
import { ReactComponent as IconTimes } from '../../assets/icons/times.svg'
import { ReactComponent as IconMicrophone } from '../../assets/icons/microphone.svg'
import styles from './styles.module.scss'

export type ConversationInputProps = {
    conversationId: string
    localLanguageCode: string
    onPressSend(input?: string, media?: MessageMedia[]): void
}

const ConversationInput = ({ conversationId, localLanguageCode, onPressSend }: ConversationInputProps) => {

    const { language } = useLanguage()
    const inputRef = useRef<any>()
    const [ input, setInput ] = useState('')
    const [ placeholderVisible, setPlaceholderVisible ] = useState(true)
    const [ emojiPickerVisible, setEmojiPickerVisible ] = useState(false)
    const shiftKeyDown = useRef<boolean>(false)
    const audioRecorder = useRef<AudioRecorder | null>(null)
    const [ isRecording, setIsRecording ] = useState<boolean>(false)
    const [ recordingProgress, setRecordingProgress ] = useState<string | null>(null)

    useEffect(() => {
        audioRecorder.current = new AudioRecorder({ mimeType: 'audio/webm;codecs=opus' })
        const unsubscribe = audioRecorder.current.onprogress(progress => {
            const time = new Date(progress * 1000).toISOString().slice(14, 19)
            setRecordingProgress(time)
        })
        return () => {
            unsubscribe()
        }
    }, [])

    const handleClickSend = () => {
        inputRef.current.focus()
        inputRef.current.replaceChildren()
        const text = input
            .trim()
            .replace(/&nbsp;/g, '')
            .replace(/^\s+|\s+$/g, '') // Remove line breaks at start and end of message
        onPressSend(text)
        setInput('')
        setEmojiPickerVisible(false)
    }

    const onInput = (input: string) => {
        setInput(input)
        if (input.length && placeholderVisible) {
            setPlaceholderVisible(false)
        }
        else if (!input.length && !placeholderVisible) {
            setPlaceholderVisible(true)
        }
    }

    const onFocus = () => {
        if (input.length) {
            setPlaceholderVisible(false)
        }
    }

    const onBlur = () => {
        if (!input.length) {
            setPlaceholderVisible(true)
        }
    }

    const onKeyDown = (event: any) => {
        if (event.key === 'Enter' && !shiftKeyDown.current) {
            event.preventDefault()
            handleClickSend()
        }
        else if (event.key === 'Shift') {
            shiftKeyDown.current = true
        }
    }

    const onKeyUp = (event: any) => {
        if (event.key === 'Shift') {
            shiftKeyDown.current = false
        }
    }

    useEffect(() => {
        if (conversationId) {
            inputRef.current.focus()
        }
    }, [ conversationId ])

    const toggleEmojiPicker = useCallback(() => {
        setEmojiPickerVisible(!emojiPickerVisible)
    }, [ emojiPickerVisible ])

    const onSelectEmoji = (emoji: Emoji) => {
        setInput(input + emoji.emoji)
        inputRef.current.innerHTML = inputRef.current.innerHTML + emoji.emoji
        inputRef.current.focus()
        // Position cursor at end of input
        if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
            let range = document.createRange()
            range.selectNodeContents(inputRef.current)
            range.collapse(false)
            const sel = window.getSelection()
            if (sel) {
                sel.removeAllRanges()
                sel.addRange(range)
            }
        }
        
    }

    const startRecordingAudio = () => {
        if (audioRecorder.current) {
            audioRecorder.current.start(async file => {
                try {
                    setIsRecording(false)
                    setRecordingProgress(null)
                    const { data } = await EntiendoSDK.media.upload(file, localLanguageCode)
                    onPressSend(undefined, data)
                } catch (error) {
                    console.error(error)
                    alert('Failed to upload audio')
                }
            })
            setIsRecording(true)
        }
    }

    const stopRecordingAudio = () => {
        if (audioRecorder.current) {
            audioRecorder.current.stop()
        }
    }

    const toggleRecordingAudio = () => {
        if (audioRecorder.current?.isRecording) {
            stopRecordingAudio()
        } else {
            startRecordingAudio()
        }
    }

    const emojiIcon = useMemo(() =>
        emojiPickerVisible ?
            <IconTimes
                fill={'#ffffff'}
                className={styles['emoji-button']}
                width={32}
                height={32}
                onClick={toggleEmojiPicker} /> :
            <IconSmile
                fill={'#ffffff'}
                className={styles['emoji-button']}
                width={32}
                height={32}
                onClick={toggleEmojiPicker} />
    , [ emojiPickerVisible, toggleEmojiPicker ])

    return (
        <div>
            <EmojiPicker
                visible={emojiPickerVisible}
                onSelect={onSelectEmoji}
            />
            <div className={`${styles.input} flex relative z-10`}>
                {emojiIcon}
                {/* <input
                    ref={inputRef}
                    type="text"
                    placeholder={language.chatInputPlaceholder}
                    value={input}
                    onChange={({ target }) => setInput(target.value)}
                    onKeyDown={onKeyDown} /> */}
                <div className={styles['input-container']}>
                    <div contentEditable
                        ref={inputRef}
                        onInput={({ currentTarget }) => onInput(currentTarget.innerHTML ?? '')}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                    />
                    {placeholderVisible && (
                        <span className={styles.placeholder}>
                            {language.chatInputPlaceholder}
                        </span>
                    )}
                </div>
                <div className={styles['action-buttons']}>
                    <div className={styles.recorder + ' ' + (isRecording ? styles.recording : '')}>
                        {isRecording && (
                            <div className={styles.progress}>
                                {recordingProgress ?? '00:00'}
                            </div>
                        )}
                        <div
                            className={styles['mic-button']}
                            onClick={toggleRecordingAudio}
                        >
                            <IconMicrophone width={26} height={26} />
                        </div>
                    </div>
                    <SendButton
                        onClick={handleClickSend}
                        className={styles['send-button']}
                    />
                </div>
            </div>
        </div>
    )

}

export default ConversationInput