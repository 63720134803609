import { useMemo } from 'react'
import styles from './styles.module.scss'

export type ButtonProps = {
    value: string
    primary?: boolean
    disabled?: boolean
    className?: string
    onClick?(): void
}

const Button = ({ value, primary, disabled, className, onClick = () => {} }: ButtonProps) => {

    const computedClassName = useMemo(() => {
        let name = styles.button
        if (primary) name += ' ' + styles.primary
        if (disabled) name += ' ' + styles.disabled
        if (className) name += ' ' + className
        return name
    }, [ primary, disabled, className ])

    return (
        <button
            className={computedClassName}
            onClick={!disabled ? onClick : undefined}
        >
            {value}
        </button>
    )

}

export default Button