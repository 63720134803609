import { v4 as uuid } from 'uuid'

export function getBrowserId(): string {
    let id = localStorage.getItem('identifier')
    if (!id) {
        id = uuid()
        localStorage.setItem('identifier', id)
    }
    return id
}

export function getBrowserName() {       
    let userAgent = navigator.userAgent
    let browserName: string | undefined
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Google Chrome"
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Mozilla Firefox"
    } else if (userAgent.match(/safari/i)) {
        browserName = "Safari"
    } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera"
    } else if(userAgent.match(/edg/i)) {
        browserName = "Microsoft Edge"
    }
    return browserName        
}

export function getBrowserCountryCode(): string | undefined {
    if (typeof Intl !== 'undefined') {
        try {
            const timezones = require('../assets/timezones.json')
            const tz = (Intl.DateTimeFormat().resolvedOptions().timeZone)
            return timezones.find((t: any) => t.tz === tz)?.cc
        } catch (err) {
            console.error('Could not get country code from Intl')
        }
    }
}