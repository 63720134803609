import { useEffect, useState, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg'
import Button from '../button'
import styles from './styles.module.scss'

export type ModalAction = {
    name: string
    primary?: boolean
    onClick?(): void
}

export type ModalActions = Array<ModalAction>

export type ModalProps = {
    visible: boolean
    title?: string
    closeable?: boolean
    actions?: ModalActions
    width?: number
    onClose?(): void
}

const Modal = ({ children, visible, title, closeable = true, actions = [], width = 420, onClose = () => {} }: PropsWithChildren<ModalProps>) => {

    const [ _visible, _setVisible ] = useState(visible)

    const close = () => {
        _setVisible(false)
        onClose()
    }

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
    }

    useEffect(() => {
        if (visible && !_visible) _setVisible(true)
        else if (!visible && _visible) _setVisible(false)
    }, [ visible, _visible ])

    if (!_visible) return null

    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.modal} onClick={handleModalClick} style={{ width }}>
                <header className={styles['modal-header']}>
                    <div className="font-semibold">{title}</div>
                    <div onClick={close}>
                        <TimesIcon
                            width={26}
                            height={26}
                        />
                    </div>
                </header>
                <div className={styles['modal-content']}>
                    {children}
                </div>
                {actions.length > 0 && (
                    <footer className={styles['modal-actions']}>
                        {actions.map((action, index) => (
                            <div key={'action-'+(index + 1)} className="w-full px-1">
                                <Button 
                                    value={action.name}
                                    primary={action.primary}
                                    onClick={action.onClick}
                                />
                            </div>
                        ))}
                    </footer>
                )}
            </div>
        </div>,
        document.querySelector("#modal")!
    )
}

export default Modal