import { User } from 'entiendo-javascript-sdk'
import UserPicture from '../user-picture'

export type UserProfileProps = {
    user: User
}

const UserProfile = ({ user }: UserProfileProps) => {

    return (
        <div>
            <div className="text-center">
                {user.pictureUrl ? (
                    <UserPicture
                        url={user.pictureUrl}
                        size={142} />
                ) : null}
                <div className="text-2xl font-medium mt-2">{user.displayName}</div>
                <div className="text-text-secondary">{user.username}</div>
            </div>
        </div>
    )

}

export default UserProfile